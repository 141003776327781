<template>
    <div class="courses-list" v-if="courses.length">
        <div
            class="card-border card-border--course card-border--performance"
            v-for="course in courses"
            :key="course.id"
        >
            <router-link
                :to="{
                    name: 'performance-item',
                    params: { slug: course.slug, order_id: course.order_id }
                }"
                class="card-border__link"
            ></router-link>

            <div class="card-border__content">
                <div class="card-border__title subtitle text-blue">
                    По курсу {{ course.code }}
                </div>
            </div>
            <button class="btn btn-tr">
                <svg class="icon icon-arrow-right ">
                    <use xlink:href="#arrow-right"></use>
                </svg>
            </button>
        </div>
    </div>
    <div class="title-second" v-else>
        Статистика по курсам отсутствует.
    </div>
</template>

<script>
import store from "@/store/index.js";

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch("CUSTOMER_GET_COURSES").then(() => {
            next();
        });
    },

    computed: {
        courses() {
            return this.$store.state.customer.courses;
        }
    }
};
</script>
